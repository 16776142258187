.new-game-form {
    > div.ant-col {
        padding: 8px;
    }
    div.ant-divider {
        margin: 0;
    }

    .start-btn-container {
        text-align: center;
    }

    .ant-alert {
        text-align: center;
    }
}