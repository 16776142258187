
th.overview-player-header {
    position: relative;
}

.overview-player-label {
    position: absolute;
    transform: rotate(-30deg);
    overflow: visible;
    z-index: 999;
}

table.overview {
    .ant-table-thead {
        tr {
            th {
                border: none;
                background-color: #ffffff;
            }
        }
    }
    tbody {
        tr td {
            font-size: large;
            padding-top: 8px;
        }
    }
}